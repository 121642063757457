import { useTitle } from '@dimatech/shared/lib/hooks';
import { About as AboutFeature } from 'features/about';
import { useTranslation } from 'react-i18next';

export const About = (): JSX.Element => {
  const { t } = useTranslation();

  useTitle(t('Navigation.About'));

  return <AboutFeature />;
};

About.displayName = 'About';
