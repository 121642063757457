import { CookieConsentSelection } from '@dimatech/features-core/lib/features/cookie-consent';
import { ButtonLink } from '@dimatech/shared/lib/components/form';
import { Theme } from '@dimatech/shared/lib/themes';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Container } from './Container';
import { DimatechLogoFooter } from './DimatechLogo';

export const Footer = (): JSX.Element => {
  const { t } = useTranslation();

  const [hasSavedConsent, setHasSavedConsent] = useState(true);

  return (
    <>
      <Style>
        <Container>
          <div>
            <DimatechLogoFooter />
          </div>

          <div>
            <div>
              <p>
                <b>{t('Footer.Contact.Text')}</b>
                <br />
                <a href="mailto:info@dimatech.se">info@dimatech.se</a>
                <br />
                Box 7159
                <br />
                103 88 Stockholm
                <br />
                <a href="https://www.dimatech.se" className="b">
                  www.dimatech.se
                </a>
              </p>
            </div>

            <div>
              <p>
                <b>Support</b>
                <br />
                {t('Footer.Support.Text')}
                <br />
                <a href="mailto:support@dimatech.se" className="b">
                  support@dimatech.se
                </a>
                <br />
                <br />
                <ButtonLink
                  onClick={() => {
                    setHasSavedConsent(false);
                  }}
                >
                  {t('Footer.Cookies.Text')}
                </ButtonLink>
              </p>
            </div>
          </div>

          <FooterNavigation>
            <Link to="/about" className="link">
              {t('Navigation.About')}
            </Link>
            <span>|</span>
            <Link to="/policies" className="link">
              {t('Navigation.Policies')}
            </Link>
            <span>|</span>
            <Link to="/partners" className="link">
              {t('Navigation.Partners')}
            </Link>
          </FooterNavigation>
        </Container>
      </Style>

      <CookieConsentSelection
        hasSavedConsent={hasSavedConsent}
        setHasSavedConsent={setHasSavedConsent}
      />
    </>
  );
};

const Style = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: ${({ theme }: { theme: Theme }) =>
    theme.colors.surfaceVariant};
  color: ${({ theme }: { theme: Theme }) => theme.colors.onSurfaceVariant};

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

    padding: 40px 30px;

    > div {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 60px;

      &:last-of-type {
        gap: 10px;
      }

      a,
      > div > p > button {
        border: none;
        color: ${({ theme }: { theme: Theme }) =>
          theme.colors.onSurfaceVariant};

        &:hover {
          color: ${({ theme }: { theme: Theme }) => theme.colors.primary};
        }
      }
    }
  }
`;

const FooterNavigation = styled.div`
  display: flex;
  justify-content: space-between;

  margin-top: 20px;

  .link {
    font-size: 18px;

    @media (min-width: 768px) {
      margin-top: 0;
    }
  }

  span {
    font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.l};
  }
`;
