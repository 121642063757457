import { useTitle } from '@dimatech/shared/lib/hooks';
import { PoliciesReadMore as PoliciesReadMoreFeature } from 'features/policies/PoliciesReadMore';
import { useTranslation } from 'react-i18next';

export const PoliciesReadMore = (): JSX.Element => {
  const { t } = useTranslation();

  useTitle(t('Policies.PoliciesReadMore.Title'));

  return <PoliciesReadMoreFeature />;
};

PoliciesReadMore.displayName = 'PoliciesReadMore';
