import { useTitle } from '@dimatech/shared/lib/hooks';
import { Partners as PartnersFeature } from 'features/partners';
import { useTranslation } from 'react-i18next';

export const Partners = (): JSX.Element => {
  const { t } = useTranslation();

  useTitle(t('Navigation.Partners'));

  return <PartnersFeature />;
};

Partners.displayName = 'Partners';
