import { Table, Td, Th, Tr } from '@dimatech/shared/lib/components/table';
import {
  Heading2,
  Heading3,
  Heading5,
} from '@dimatech/shared/lib/components/typography';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PoliciesView } from './PoliciesView';

// cspell:disable
/* eslint-disable max-lines-per-function */

export const InformationSecurity = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <PoliciesView>
      <div>
        <div>
          <Link to="/policies">{t('Policies.Title')}</Link> /{' '}
          <Link to="/policies-read-more">
            {t('Policies.PoliciesReadMore.Title')}
          </Link>{' '}
          / {t('Policies.InformationSecurity.Title')}
        </div>

        <div>
          <Heading2>{t('Policies.InformationSecurity.Title')}</Heading2>
          <Heading3>{t('Policies.PoliciesReadMore.Title')}</Heading3>
          <p>2023-01-01</p>
          <Heading3>Inledning</Heading3>
          <p>
            Dimatech bygger molnbaserade lösningar för offentlig sektor. Våra
            tjänster skall vara lättanvända, intuitiva och väldesignade. Vi
            strävar efter att överträffa våra kunders förväntningar inom alla
            aspekter av användningen av tjänsterna. Att hålla våra kunders
            uppgifter säkra är ett av våra prioriterade områden. Det är av stor
            vikt för oss att vi har implementerat lämpliga tekniska och
            organisatoriska åtgärder för att säkerställa att all data som
            skickas eller sparas i Dimatechs tjänster hanteras på ett säkert
            sätt. Våra kunder skall kunna förlita sig på att all data är korrekt
            och samtidigt vara förvissade om att den data som samlas in och
            sparas i våra tjänster har ett tillräckligt skydd. Det är nödvändigt
            för att vi ska uppnå våra verksamhetsmål, och för att kunder och
            dess anställda ska känna förtroende för oss. Det innebär att vi
            jobbar aktivt med att se till att informationstillgångar som våra
            kunder har i våra tjänster skall finns tillgängliga när de behövs,
            att de är korrekta, och att obehöriga inte får åtkomst till dem.
            Alla medarbetare och samarbetspartners deltar i detta arbete. Genom
            att arbeta systematiskt och långsiktigt upprätthåller vi ett
            tillräckligt skydd som är anpassat efter vår verksamhets
            förutsättningar och behov. Arbetet med informationssäkerhet ska
            omfatta alla delar av vår verksamhet och alla de
            informationstillgångar som vi äger eller hanterar.
          </p>
          <p>
            Denna policy beskriver de övergripande principer som ska gälla för
            informationssäkerhetsarbetet inom Dimatech.
          </p>

          <Heading3>Mål</Heading3>
          <p>
            Målet för Dimatechs informationssäkerhetsarbete är att skydda
            informationen inom verksamheten och för våra kunder. Skyddet ska
            vara anpassat till skyddsvärde, risk och lagkrav och därigenom
            möjliggöra för våra kunders verksamheter att uppnå sina mål.
          </p>
          <p>
            En god informationssäkerhet inom Dimatech främjar våra tjänsters
            funktionalitet, kvalitet och effektivitet, användares rättigheter
            och personliga integritet samt vår förmåga att förebygga och hantera
            allvarliga störningar och kriser samt förtroendet vår
            informationshantering.
          </p>

          <Heading3>Innebörd</Heading3>
          <p>
            Informationssäkerhetsarbetet innebär att värdera all information
            efter sin känslighet och med hjälp av administrativa och tekniska
            skyddsåtgärder säkerställa att den finns tillgänglig när den behövs,
            att den är korrekt och att obehöriga inte kan få tillgång till den.
          </p>
          <p>Säkerhetsaspekter</p>
          <ul>
            <li>
              Konfidentialitet (rätt person): Information får inte göras
              tillgänglig eller avslöjas på ett sådant sätt att den personliga
              integriteten eller sekretessen hotas.
            </li>
            <li>
              Riktighet (rätt information): Informationen få inte förändras
              eller gå förlorad, av misstag, genom inverkan av obehörig eller på
              grund av tekniskt fel.
            </li>
            <li>
              Tillgänglighet (rätt tid och plats): Informationen ska kunna
              användas i förväntad utsträckning, inom önskad tid och på rätt
              plats.
            </li>
          </ul>
          <p>
            Skyddet av informationstillgångar och informationssystem ska vara
            utformat så att verksamhetens krav på dessa säkerhetsaspekter
            uppfylls. Skyddsåtgärder skall vara kostnadseffektiva och stå i
            proportion till värdet av informationen och de negativa konsekvenser
            en otillräcklig säkerhet kan medföra.
          </p>

          <Heading3>Ansvar</Heading3>
          <p>
            Alla har ett ansvar för att säkerheten fungerar hos Dimatech. Den
            som upptäcker brister i informationssäkerheten måste rapportera
            detta. Alla medarbetare måste också rapportera händelser som kan
            göra att våra informationstillgångar utsätts för risker. Roller inom
            Dimatech som har ett särskilt ansvar för att vår säkerhetspolicy
            efterföljs är:
          </p>
          <p>
            VD - ska säkerställa att all informationshantering inom Dimatechs
            verksamhet sker i enlighet med denna policy samt tillhörande
            riktlinjer och tillämpningsanvisningar för informationssäkerhet.
          </p>
          <p>
            Produktchef - ansvar att samordna och följa upp det för
            organisationen och verksamheten gemensamma
            informationssäkerhetsarbetet.
          </p>
          <p>
            Vidare har varje anställd ansvarar för att uppställda
            säkerhetsregler följs samt att störningar och fel i
            informationssystem, utrustning och informationsinnehåll rapporteras
            enligt fastställda rutiner.
          </p>
          <p>
            I övrigt gäller att informationssäkerhet följer verksamhetsansvaret.
            Begreppsförklaringar (bygger på definitioner hämtade från
            ”Terminologi för informationssäkerhet, SIS HB550 utgåva 3, SIS
            förlag):
          </p>
          <ul>
            <li>
              Informationstillgångar är allt som innehåller information och allt
              som bär på information.
            </li>
            <li>
              Informationssäkerhet är säkerhet beträffande
              informationstillgångar rörande förmågan att upprätthålla önskad
              konfidentialitet, riktighet och tillgänglighet.
            </li>
            <li>
              Konfidentiell information får inte nås av eller avslöjas för någon
              obehörig. Oftast gäller det innehållet i en informationstillgång
              men ibland är även tillgångens existens hemlig.
            </li>
            <li>
              Riktig information innebär att informationen inte får obehörigen
              förändras, inte av misstag och inte på grund av en
              funktionsstörning.
            </li>
            <li>
              Tillgänglig information innebär att informationen går att utnyttja
              av behörig användare när det behövs och så mycket som det behövs.
            </li>
          </ul>
          <p>
            Varje år ska ledningsgruppen se över och eventuellt revidera
            informationssäkerhetspolicyn.
          </p>
          <br />
          <p>
            <i>Våra kunders ansvar:</i>
          </p>
          <p>
            Kunderna är personuppgiftsansvariga. De bör teckna ett
            personuppgiftbiträdesavtal med oss.
          </p>
          <p>
            Kunderna bör vidare arbeta med informationsklassificering där
            information i våra tjänster klassificeras.
          </p>
          <p>
            Kunderna bör utse en ägare av informationen. Informationsägaren
            ansvarar för att klassificera informationen och ställa de
            säkerhetskrav som krävs för informationshantering.
          </p>
          <p>
            Kunderna bör utse en systemägare som ansvarar för att
            säkerhetskraven på systemet uppfylls.
          </p>
          <Heading3>Riktlinjer</Heading3>
          <p>Nedan följer ett antal riktlinjer som vi på Dimatech följer. </p>
          <Heading5>
            Efterlevnad av förordningar, lagar och regleringar{' '}
          </Heading5>
          <p>
            Vi strävar efter att alltid och till fullo efterleva alla lagar och
            regler. Vi bevakar även området för att vara förberedda på
            eventuella förändringar av lagar och regler.
          </p>
          <Heading5>Personuppgifter - dataskyddsförordningen</Heading5>
          <p>
            Vår huvudsakliga lag att förhålla sig till är
            Dataskyddsförordningen. Syftet med Dataskyddsförordningen (GDPR) är
            att skydda människor mot att deras personliga integritet kränks
            genom behandling av personuppgifter. Personuppgifter definieras som
            all slags information som direkt eller indirekt kan hänföras till en
            fysisk person i livet. Genom att i lagen begränsa och styra de sätt
            på vilka man får hantera personuppgifter ges informationen ett
            särskilt skydd. Lagen innehåller även regler om vilka tekniska och
            organisatoriska säkerhetsåtgärder den som hanterar personuppgifter
            ska vidta. Dimatechs huvudsakliga roll utifrån denna förordning är
            att säkerställa sina åtaganden som personuppgiftsbiträde. Vi
            upprättar därför PUB-avtal med våra kunder. Ansvaret för
            Personuppgifterna och tillsättande av ev. Dataskyddsombud ligger hos
            våra kunder.
          </p>
          <a href="https://www.imy.se/verksamhet/dataskydd/det-har-galler-enligt-gdpr/personuppgiftsansvariga-och-personuppgiftsbitraden/ ">
            https://www.imy.se/verksamhet/dataskydd/det-har-galler-enligt-gdpr/personuppgiftsansvariga-och-personuppgiftsbitraden/
          </a>
          <br />

          <a href="https://www.imy.se/verksamhet/dataskydd/det-har-galler-enligt-gdpr/introduktion-till-gdpr/dataskyddsforordningen-i-fulltext/">
            https://www.imy.se/verksamhet/dataskydd/det-har-galler-enligt-gdpr/introduktion-till-gdpr/dataskyddsforordningen-i-fulltext/
          </a>
          <br />
          <br />

          <Heading5>Informationsklassning </Heading5>
          <p>
            Syftet med informationssäkerhetsklassning är att tydliggöra att
            olika typer av information har olika värde för verksamheten och
            därmed måste skyddas på olika nivåer. Vi bedömer hur den data vi
            hanterar bör klassificeras. Våra medarbetare skall vara medvetna om
            klassificeringen. När vi utvecklar nya system och funktioner är vi
            uppmärksamma på huruvida ny kunddata omfattas, och klassificerar den
            innan beslut om design och hantering tas.
          </p>
          <p>
            Dimatech ska ansvara för att lösningen är utformad på ett sådant
            sätt att informationssäkerheten under avtalsperioden kan
            upprätthållas på en nivå som motsvarar skyddsbehovet. Denna nivå
            motsvaras av de krav som kan genereras i KLASSA{' '}
            <a href="https://klassa-info.skl.se/">
              (https://klassa-info.skl.se/)
            </a>{' '}
            som följer:
          </p>
          <p>Område klassas via nivåbedömning (0 till 4); </p>
          <p>
            där 0 är Ingen/försumbar skada och 4 är Synnerligen allvarlig skada
            om information röjs eller blir tillgänglig.
          </p>
          <p>Exempel:</p>

          <Table $striped>
            <thead>
              <Tr>
                <Th>Område</Th>
                <Th>Nivå</Th>
              </Tr>
            </thead>
            <tbody>
              <Tr>
                <Td>Konfidentialitet</Td>
                <Td>1</Td>
              </Tr>
              <Tr>
                <Td>Riktighet</Td>
                <Td>1</Td>
              </Tr>
              <Tr>
                <Td>Tillgänglighet</Td>
                <Td>1</Td>
              </Tr>
            </tbody>
          </Table>
          <br />

          <Heading5>Kontinuitetsplanering</Heading5>
          <p>
            Det huvudsakliga målet med kontinuitetsplanering är att säkerställa
            att eventuella avbrott i tillgången till information och system inte
            får allvarliga konsekvenser för verksamheten eller de verksamheter
            vi stödjer. Det innebär att det måste finnas planer och rutiner för
            att säkerställa att verksamheten kan fortsätta bedrivas och återgå
            till normalläget inom rimlig tid. Det är också viktigt att
            fastställa vem som ansvarar för vad i en krissituation. Alla berörda
            ska veta hur, när och vilka åtgärder som ska vidtas om ett avbrott
            inträffar.
          </p>
          <Heading5>Kontinuerliga förbättringar</Heading5>
          <p>
            Vår utvecklingspraxis säkerställer att vi har säkerhet i åtanke i
            alla skeden av vår utvecklingslivscykel. Även om inget system är
            helt säkert, kommer vi att göra vårt yttersta för att minimera alla
            typer av risker.
          </p>
          <p>Exempel på vår utvecklingspraxis</p>
          <ul>
            <li>
              Tydliga kodkonventioner som upprätthålls av statisk kodanalys;
            </li>
            <li>
              Användning av välkända ramverk för att skydda mot vanliga
              attackvektorer (XSS, CSRF, SQL Injection);
            </li>
            <li>
              Incidentresponsplaner upprätthålls och följs för att snabbt agera
              på incidenter;
            </li>
            <li>
              Kontinuerlig kontroll för att hålla biblioteken uppdaterade;
            </li>
            <li>Kontinuerliga integrationsbyggen och tester;</li>
            <li>
              Ständig förbättringsprocess med produktteamet där säkerhetsfrågor
              är en stående post;
            </li>
            <li>
              Sårbarhetsskanning görs återkommande för att säkerställa att
              systemet är skyddat från alla nya säkerhetshot.
            </li>
            <li>Penetrationstester görs av Cygate på deras infrastruktur</li>
            <li>
              All kod är peer-reviewed för att hitta buggar och säkerhetshål
              tidigt
            </li>
            <li>Alla releaser testas innan de slås samman till produktion</li>
            <li>
              Lösenord förvaras alltid säkert eller som del av konfiguration
            </li>
          </ul>

          <Heading5>
            Incidenthantering och rutiner för både informationssäkerhets- och
            personuppgiftsincidenter{' '}
          </Heading5>
          <p>
            Vi har lämpliga tekniska och organisatoriska åtgärder på plats för
            att skydda personuppgifter, såväl som andra uppgifter mot oavsiktlig
            eller olaglig förstörelse eller oavsiktlig förlust, ändring,
            obehörigt avslöjande eller åtkomst och mot alla andra olagliga
            former av behandling.
          </p>
          <p>
            Incidenter måste rapporteras så att det finns kännedom om tillbud
            och faktiska händelser. På så vis kan Dimatech och våra kunder
            reagera på enskild händelse men också på serier av händelser. Vid
            händelse av en allvarlig incident så stoppar vi omedelbart all
            access till systemet, samt alla utskick från systemet. Därefter
            analyserar vi vilken data och vilka kunder som kan ha kommit att
            påverkas och informerar om situationen. Därefter görs en grundlig
            analys av incidenten, fel åtgärdas och samtliga påverkade erhåller
            en detaljerad uppföljning om vad som hänt, vilken data som påverkats
            samt vilka åtgärder som vidtagits.
          </p>

          <Heading5>Kompetens</Heading5>
          <p>
            För att upprätthålla kompetens inom informationssäkerhetsområdet
            finns flera möjligheter:
          </p>
          <ul>
            <li>
              Utbildningar - Det finns både webbaserade utbildningar och
              utbildningar &quot;ansikte mot ansikte&quot;.
            </li>
            <li>
              Föreläsningar och föredrag - Inom speciella områden eller med
              allmän information, till exempel i samband med företagets vecko-
              och/eller månadsmöte.
            </li>
          </ul>

          <Heading5>
            Åtkomsthantering, Behörighetskontroll & Allmän försiktighet
          </Heading5>
          <p>
            Identitets- och åtkomsthantering ska i samband med andra
            säkerhetshöjande åtgärder säkerställa att Dimatechs system erhåller
            ett adekvat skydd från obehörig eller otillåten åtkomst. Med en bra
            och tillförlitlig behörighetshantering säkerhetsställs att
            information inte kommer orätta tillhanda samt att system används på
            ett sådant vis att konfidentialitet, tillgänglighet och riktighet
            säkerhetsställs.
          </p>
          <p>
            Vi är ett mindre team som känner varandra mycket väl sedan flera år
            tillbaka. Vi släpper inte in någon ny okänd person i någon del av
            systemet innan en god professionell relation samt förtroende har
            etablerats. Under anställning genomförs flera intervjuer, vi följer
            upp referenser samt kontrollerar ID. Vi gör också
            säkerhetskontroller i samband med anställning, t.ex. genom kontroll
            i belastningsregister.
          </p>

          <Heading5>Datasäkerhet</Heading5>
          <ul>
            <li>
              Processing
              <ul>
                <li>
                  Vi samarbetar med säkra och stora leverantörer. Vi har valt
                  ett partnerskap med Telia Cygate. Cygate kontrollerar
                  kontinuerligt säkerheten i sina data centers och säkerställer
                  att de möter krav enligt gällande industristandarder. Genom
                  Telia Cygate omfattas vi av deras program för såväl fysisk som
                  virtuell säkerhet, här finns detta utförligt
                  <a href="https://www.teliacygate.se/erbjudande/sakerhet/sakerhetsskydd/">
                    {' '}
                    https://www.teliacygate.se/erbjudande/sakerhet/sakerhetsskydd/
                  </a>
                </li>
              </ul>
            </li>
            <li>
              Data at rest
              <ul>
                <li>
                  Genom samarbetet med Telia Cygate lagrar vi all data innanför
                  Sveriges gränser. Cygate är också vår databasleverantör och
                  ger oss ett starkt och automatiskt skydd på detta område.
                </li>
              </ul>
            </li>
            <li>
              Data in transit
              <ul>
                <li>Vi använder standardkryptering.</li>
              </ul>
            </li>
            <li>
              Backups and Data loss prevention
              <ul>
                <li>
                  Data säkerhetskopieras kontinuerligt och vi har ett
                  automatiskt failover-system om huvudsystemet misslyckas. Vi
                  får kraftfullt och automatiskt skydd genom Cygate varande vår
                  databasleverantör.
                </li>
              </ul>
            </li>
            <li>
              User Passwords
              <ul>
                <li>
                  Vi envägskrypterar lösenord och Dimatech kan aldrig se en
                  användare eller kunds lösenord. Användare kan själv återställa
                  lösenordet via e-post.
                </li>
              </ul>
            </li>
            <li>
              Employee Passwords
              <ul>
                <li>
                  Vi tillämpar 2FA där det är tillämpligt och alla anställda
                  använder skärmlås när de inte är vid sin arbetsstation.
                </li>
              </ul>
            </li>
          </ul>

          <Heading5>
            Bevarande av uppgifter, gallring och förstöring av data
          </Heading5>
          <p>
            När vi inte längre har något behov av datan enligt den verksamhet vi
            bedriver, så raderas datan. Det tar ca 6 månader innan datan är
            raderad på samtliga backuper. Data raderas även på uppmaning av kund
            oavsett anledning.
          </p>

          <Heading5>
            Auktoriserad/ej auktoriserad användning och delande av data
          </Heading5>
          <p>
            Medarbetare har enbart access till de delar av systemet de har behov
            av, accessen är behovsmotiverad. När behovet är tillgodosett, så tar
            vi bort accessen. Vår inställning är att vara restriktiva vad gäller
            access.
          </p>

          <Heading5>Kontakt</Heading5>
          <p>
            Dimatech AB är ett svenskt aktiebolag med organisationsnummer
            556805-9538.
          </p>

          <Heading5>Ändringar av denna säkerhetspolicy</Heading5>
          <p>
            Denna säkerhetspolicy är inte en del av villkoren. Lagar,
            förordningar, branschstandarder och vår verksamhet är i ständig
            förändring, vilket kräver att vi gör ändringar i säkerhetspolicyn .
            Vi kommer att publicera ändringarna på vår webbsida och uppmanar dig
            att läsa vår säkerhetspolicy för att hålla dig informerad. Om vi gör
            ändringar som väsentligt ändrar dina integritetsrättigheter kommer
            vi att tillhandahålla ytterligare meddelande via Tjänsterna eller
            via e-post. Om du inte håller med om ändringarna i denna
            säkerhetspolicy bör du kontakta oss för att avaktivera ditt konto.{' '}
          </p>
        </div>
      </div>
    </PoliciesView>
  );
};
