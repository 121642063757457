import { UserAgreementDikios } from '@dimatech/shared/lib/components/user-agreement';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PoliciesView } from './PoliciesView';

export const UserAgreementsDikios = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <PoliciesView>
      <div>
        <div>
          <Link to="/policies">{t('Policies.Title')}</Link> /{' '}
          <Link to="/user-terms-read-more">
            {t('Policies.UserTermsReadMore.Title')}
          </Link>{' '}
          / {t('Policies.UserTermsReadMore.DownloadDikios')}
        </div>
        <div>
          <UserAgreementDikios />
        </div>
      </div>
    </PoliciesView>
  );
};

UserAgreementsDikios.displayName = 'UserAgreementsDikios';
