import { Heading2 } from '@dimatech/shared/lib/components/typography';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PoliciesView } from './PoliciesView';

export const PoliciesReadMore = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <PoliciesView>
      <div>
        <div>
          <Link to="/policies">{t('Policies.Title')}</Link> /{' '}
          {t('Policies.PoliciesReadMore.Title')}
        </div>

        <div>
          <Heading2>{t('Policies.PoliciesReadMore.Title')}</Heading2>
          <p>{t('Policies.PoliciesReadMore.Text')}</p>
          <p>{t('Policies.PoliciesReadMore.Text2')}</p>
          <p>{t('Policies.PoliciesReadMore.Text3')}</p>
          <p>{t('Policies.PoliciesReadMore.Text4')}</p>

          <Link to="/information-security">
            {t('Policies.PoliciesReadMore.LinkText')}
          </Link>
        </div>
      </div>
    </PoliciesView>
  );
};
