import { useTitle } from '@dimatech/shared/lib/hooks';
import { UserAgreementsDimios as UserAgreementsDimiosFeature } from 'features/policies/UserAgreementsDimios';
import { useTranslation } from 'react-i18next';

export const UserAgreementsDimios = (): JSX.Element => {
  const { t } = useTranslation();

  useTitle(t('Policies.UserTermsReadMore.DownloadDimios'));

  return <UserAgreementsDimiosFeature />;
};

UserAgreementsDimios.displayName = 'UserAgreementsDimios';
