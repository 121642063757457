import { CookieConsent } from '@dimatech/features-core/lib/features/cookie-consent';
import { useGoogleAnalytics } from '@dimatech/features-core/lib/hooks';
import { ApplicationProvider } from '@dimatech/shared/lib/application';
import { LocalizationProvider } from '@dimatech/shared/lib/localization';
import { ThemeProvider } from '@dimatech/shared/lib/themes';
import { TrackingProvider } from '@dimatech/shared/lib/tracking';
import { themes } from 'assets/themes';
import { GlobalStyles } from 'assets/themes/GlobalStyles';
import { Route, Routes } from 'react-router-dom';
import { About } from 'views/About';
import { Dikios } from 'views/Dikios';
import { Dimios } from 'views/Dimios';
import { Dios } from 'views/Dios';
import { Home } from 'views/Home';
import { InformationSecurity } from 'views/InformationSecurity';
import { Newsletter } from 'views/Newsletter';
import { Partners } from 'views/Partners';
import { Pios } from 'views/Pios';
import { Policies } from 'views/Policies';
import { PoliciesReadMore } from 'views/PoliciesReadMore';
import { UserAgreementsDikios } from 'views/UserAgreementsDikios';
import { UserAgreementsDimios } from 'views/UserAgreementsDimios';
import { UserAgreementsDios } from 'views/UserAgreementsDios';
import { UserAgreementsPios } from 'views/UserAgreementsPios';
import { UserTermsReadMore } from 'views/UserTermsReadMore';

const App = (): JSX.Element => {
  useGoogleAnalytics(false);

  return (
    <LocalizationProvider>
      <TrackingProvider>
        <ThemeProvider themes={themes}>
          <ApplicationProvider>
            <CookieConsent />

            <GlobalStyles />
            <Routes>
              <Route path="*" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/dimios" element={<Dimios />} />
              <Route path="/dios" element={<Dios />} />
              <Route path="/pios" element={<Pios />} />
              <Route path="/dikios" element={<Dikios />} />
              <Route path="/partners" element={<Partners />} />
              <Route path="/policies" element={<Policies />} />
              <Route path="/newsletter" element={<Newsletter />} />
              <Route
                path="/policies-read-more"
                element={<PoliciesReadMore />}
              />
              <Route
                path="/user-terms-read-more"
                element={<UserTermsReadMore />}
              />
              <Route
                path="/user-agreements-dimios"
                element={<UserAgreementsDimios />}
              />
              <Route
                path="/user-agreements-dios"
                element={<UserAgreementsDios />}
              />
              <Route
                path="/user-agreements-pios"
                element={<UserAgreementsPios />}
              />
              <Route
                path="/user-agreements-dikios"
                element={<UserAgreementsDikios />}
              />
              <Route
                path="/information-security"
                element={<InformationSecurity />}
              />
            </Routes>
          </ApplicationProvider>
        </ThemeProvider>
      </TrackingProvider>
    </LocalizationProvider>
  );
};

export default App;
