var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Buttons, ButtonSecondary, Switch, } from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { Heading4 } from '@dimatech/shared/lib/components/typography';
import { Breakpoints } from '@dimatech/shared/lib/themes';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useGoogleAnalytics } from '../../hooks';
export var CookieConsentSelection = function (_a) {
    var hasSavedConsent = _a.hasSavedConsent, setHasSavedConsent = _a.setHasSavedConsent;
    var t = useTranslation().t;
    var googleAnalytics = useGoogleAnalytics(false);
    googleAnalytics.initialize();
    var _b = useState(false), isSettingsShown = _b[0], setIsSettingsShown = _b[1];
    var _c = useState(true), isAnalyticsCookiesApproved = _c[0], setIsAnalyticsCookiesApproved = _c[1];
    if (hasSavedConsent) {
        return null;
    }
    var saveConsent = function (hasGivenConsent) {
        googleAnalytics.setHasGivenConsent(hasGivenConsent);
        googleAnalytics.initialize();
        setHasSavedConsent(true);
        window.location.reload();
    };
    return (_jsxs(Modal, __assign({ title: t('CookieConsent.Title'), style: { paddingBottom: 30, width: '90%', maxWidth: 900 } }, { children: [_jsxs(ContainerStyle, { children: [!isSettingsShown && _jsx("div", { children: t('CookieConsent.Text') }), isSettingsShown && (_jsxs(_Fragment, { children: [_jsx("div", { children: t('CookieConsent.SettingsText') }), _jsxs(Style, { children: [_jsx(Heading4, { children: t('CookieConsent.NecessaryCookies.Title') }), _jsxs("div", { children: [_jsx("div", { children: t('CookieConsent.NecessaryCookies.Text') }), _jsx("div", { children: _jsx(Switch, { label: t('CookieConsent.NecessaryCookies.AlwaysOn'), setIsChecked: function () {
                                                        // Do nothing
                                                    }, isLabelRightAligned: true, isDisabled: true, isChecked: true }) })] })] }), _jsxs(Style, { children: [_jsx(Heading4, { children: t('CookieConsent.AnalyticsCookies.Title') }), _jsxs("div", { children: [_jsx("div", { children: t('CookieConsent.AnalyticsCookies.Text') }), _jsx("div", { children: _jsx(Switch, { label: t('CookieConsent.AnalyticsCookies.Approve'), setIsChecked: function (value) {
                                                        setIsAnalyticsCookiesApproved(value);
                                                    }, isLabelRightAligned: true, isChecked: isAnalyticsCookiesApproved }) })] })] })] }))] }), _jsxs(Buttons, __assign({ style: { flexWrap: 'wrap-reverse' } }, { children: [!isSettingsShown && (_jsx(ButtonSecondary, __assign({ type: "button", onClick: function () { return setIsSettingsShown(true); } }, { children: t('CookieConsent.Settings') }))), isSettingsShown && (_jsx(ButtonSecondary, __assign({ type: "button", onClick: function () {
                            setIsSettingsShown(false);
                            setIsAnalyticsCookiesApproved(true);
                        } }, { children: t('Common.Form.Cancel') }))), !isAnalyticsCookiesApproved && (_jsx(ButtonSecondary, __assign({ type: "button", onClick: function () { return saveConsent(false); } }, { children: t('CookieConsent.ApproveOnlyNecessary') }))), _jsx(Button, __assign({ type: "button", onClick: function () { return saveConsent(true); } }, { children: t('CookieConsent.ApproveAll') }))] }))] })));
};
CookieConsentSelection.displayName = 'CookieConsentSelection';
var ContainerStyle = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  overflow-y: scroll;\n  max-height: 80%;\n  padding: 10px;\n"], ["\n  overflow-y: scroll;\n  max-height: 80%;\n  padding: 10px;\n"])));
var Style = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: 20px;\n\n  > div {\n    display: flex;\n    gap: 20px;\n\n    font-size: ", ";\n\n    > div:last-of-type {\n      width: 20%;\n      min-width: 120px;\n    }\n  }\n\n  @media screen and (max-width: ", ") {\n    > div {\n      display: flex;\n      flex-wrap: wrap;\n    }\n  }\n"], ["\n  margin-top: 20px;\n\n  > div {\n    display: flex;\n    gap: 20px;\n\n    font-size: ", ";\n\n    > div:last-of-type {\n      width: 20%;\n      min-width: 120px;\n    }\n  }\n\n  @media screen and (max-width: ", ") {\n    > div {\n      display: flex;\n      flex-wrap: wrap;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fonts.size.s;
}, Breakpoints.small);
var templateObject_1, templateObject_2;
