import { Heading2 } from '@dimatech/shared/lib/components/typography';
import { ProductPrimaryColor } from '@dimatech/shared/lib/themes';
import img_dikios_analysis_per_statement from 'assets/images/dikios-analysis-per-statement.png';
import img_dikios_internal_comparison from 'assets/images/dikios-internal-comparison.png';
import img_dikios_model_doughnut from 'assets/images/dikios-model-doughnut.png';
import img_dikios_product_hero from 'assets/images/dikios-product-hero.png';
import { Button, Buttons, ButtonsHero } from 'components/Button';
import { Container } from 'components/Container';
import { HeroSubTitle, HeroText, HeroTitle } from 'components/Hero';
import { HeroProduct } from 'components/HeroProduct';
import { HeroProductIntro } from 'components/HeroProductIntro';
import { Section } from 'components/Section';
import { View } from 'components/View';
import { Trans, useTranslation } from 'react-i18next';

import { EventType } from '@dimatech/shared/lib/models';
import { trackEvent } from '@dimatech/shared/lib/tracking';
import img_hero from 'assets/images/dikios-hero.jpg';

export const Dikios = (): JSX.Element => {
  const { t } = useTranslation();

  const bookDemo = () => {
    trackEvent(EventType.EmailBookDemo, {
      product: 'Dikios',
    });

    window.location.href = `mailto:sales@dimatech.se?subject=${t(
      'RegisterButton.MailSubject',
      { product: 'Dikios' }
    )}`;
  };

  return (
    <View>
      <HeroProduct image={img_hero}>
        <HeroTitle>{t('Dikios.Header.Title')}</HeroTitle>
        <HeroSubTitle>{t('Dikios.Header.SubTitle')}</HeroSubTitle>

        <ButtonsHero>
          <Button
            text={t('BookDemoButton.Text')}
            color={ProductPrimaryColor.Dikios}
            handleClick={bookDemo}
          />

          <Button
            text={t('Dikios.Header.ButtonText')}
            color={ProductPrimaryColor.Dikios}
            handleClick={() =>
              (window.location.href = 'https://dikios.dimatech.se/register')
            }
            light
          />
        </ButtonsHero>
      </HeroProduct>

      <HeroProductIntro color={ProductPrimaryColor.Dikios}>
        <Container>
          <div>
            <HeroTitle>{t('Dikios.Intro.Title')}</HeroTitle>

            <HeroText style={{ maxWidth: 600, width: '100%' }}>
              {t('Dikios.Intro.Text')}
            </HeroText>

            <img
              src={img_dikios_product_hero}
              alt={t('Dikios.Header.Title')}
              style={{ maxHeight: 350, width: '100%' }}
            />
          </div>
        </Container>
      </HeroProductIntro>

      <Section>
        <Container>
          <div>
            <Heading2>{t('Dikios.Information.Experiences.Title')}</Heading2>

            <div>
              <Trans i18nKey="Dikios.Information.Experiences.Text" />
            </div>
          </div>
        </Container>
      </Section>

      <Section style={{ marginBottom: 20 }}>
        <Container>
          <div>
            <Heading2>{t('Dikios.Information.Benefits.Title')}</Heading2>

            <div>
              <Trans i18nKey="Dikios.Information.Benefits.Text" />
            </div>
          </div>

          <div className="image hide-xs">
            <img
              src={img_dikios_internal_comparison}
              alt="Dikios"
              className="no-shadow"
            />
          </div>
        </Container>
      </Section>

      <Section>
        <Container>
          <div className="image-left hide-xs">
            <img
              src={img_dikios_analysis_per_statement}
              alt="Dikios"
              className="no-shadow"
            />
          </div>
          <div>
            <Heading2>{t('Dikios.Information.Why.Title')}</Heading2>

            <div>
              <Trans i18nKey="Dikios.Information.Why.Text" />
            </div>

            <Buttons>
              <Button
                text={t('BookDemoButton.Text')}
                color={ProductPrimaryColor.Dikios}
                handleClick={bookDemo}
              />

              <Button
                text={t('Dikios.Header.ButtonText')}
                color={ProductPrimaryColor.Dikios}
                handleClick={() =>
                  (window.location.href = 'https://dikios.dimatech.se/register')
                }
                light
              />
            </Buttons>
          </div>
        </Container>
      </Section>

      <Section>
        <Container>
          <div>
            <Heading2>{t('Dikios.Information.Video.Title')}</Heading2>

            <p>
              <Trans
                i18nKey="Dikios.Information.Video.Text"
                values={{
                  url: 'https://dikios.dimatech.se',
                }}
              >
                <a
                  href="https://dikios.dimatech.se"
                  target="_blank"
                  rel="noreferrer"
                >
                  {0}
                </a>
              </Trans>
            </p>
          </div>

          <div className="image hide-xs">
            <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
              <iframe
                src="https://player.vimeo.com/video/919947488?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  border: 'none',
                }}
                title="Digital kompetens Dikios"
              />
            </div>
            <script src="https://player.vimeo.com/api/player.js" />
          </div>
        </Container>
      </Section>

      <Section>
        <Container>
          <div className="image-left hide-xs">
            <img
              src={img_dikios_model_doughnut}
              alt="Dikios model"
              className="no-shadow"
            />
          </div>
          <div>
            <Heading2>{t('Dikios.Information.Model.Title')}</Heading2>

            <div>
              <Trans i18nKey="Dikios.Information.Model.Text" />
            </div>
          </div>
        </Container>
      </Section>

      <Section>
        <Container>
          <div>
            <Heading2>{t('Dikios.Information.Order.Title')}</Heading2>

            <div>
              <Trans i18nKey="Dikios.Information.Order.Text" />
            </div>

            <Buttons>
              <Button
                text={t('BookDemoButton.Text')}
                color={ProductPrimaryColor.Dikios}
                handleClick={bookDemo}
              />

              <Button
                text={t('Dikios.Header.ButtonText')}
                color={ProductPrimaryColor.Dikios}
                handleClick={() =>
                  (window.location.href = 'https://dikios.dimatech.se/register')
                }
                light
              />
            </Buttons>
          </div>

          <div />
        </Container>
      </Section>
    </View>
  );
};

Dikios.displayName = 'Dikios';
