import { useTitle } from '@dimatech/shared/lib/hooks';
import { Newsletter as NewsletterFeature } from 'features/home/Newsletter';
import { useTranslation } from 'react-i18next';

export const Newsletter = (): JSX.Element => {
  const { t } = useTranslation();

  useTitle(t('Home.Newsletter.Title'));

  return <NewsletterFeature />;
};

Newsletter.displayName = 'Newsletter';
