import { useTitle } from '@dimatech/shared/lib/hooks';
import { UserAgreementsPios as UserAgreementsPiosFeature } from 'features/policies/UserAgreementsPios';
import { useTranslation } from 'react-i18next';

export const UserAgreementsPios = (): JSX.Element => {
  const { t } = useTranslation();

  useTitle(t('Policies.UserTermsReadMore.DownloadPios'));

  return <UserAgreementsPiosFeature />;
};

UserAgreementsPios.displayName = 'UserAgreementsPios';
