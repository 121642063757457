import { useTitle } from '@dimatech/shared/lib/hooks';
import { UserAgreementsDios as UserAgreementsDiosFeature } from 'features/policies/UserAgreementsDios';
import { useTranslation } from 'react-i18next';

export const UserAgreementsDios = (): JSX.Element => {
  const { t } = useTranslation();

  useTitle(t('Policies.UserTermsReadMore.DownloadDios'));

  return <UserAgreementsDiosFeature />;
};

UserAgreementsDios.displayName = 'UserAgreementsDios';
