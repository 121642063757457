import { Heading2 } from '@dimatech/shared/lib/components/typography';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { ProductPrimaryColor } from '@dimatech/shared/lib/themes';
import img_pios_how_project_ideas from 'assets/images/pios-how-project-ideas.png';
import img_pios_how_projects from 'assets/images/pios-how-projects.png';
import img_pios_product_hero from 'assets/images/pios-product-hero.png';
import { Button, ButtonsHero } from 'components/Button';
import { Container } from 'components/Container';
import { HeroSubTitle, HeroText, HeroTitle } from 'components/Hero';
import { HeroProduct } from 'components/HeroProduct';
import { HeroProductIntro } from 'components/HeroProductIntro';
import { Question } from 'components/Question';
import { Section } from 'components/Section';
import { View } from 'components/View';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Trans, useTranslation } from 'react-i18next';

import { EventType } from '@dimatech/shared/lib/models';
import { trackEvent } from '@dimatech/shared/lib/tracking';
import img_hero from 'assets/images/pios-hero.jpg';

export const Pios = (): JSX.Element => {
  const { t } = useTranslation();

  const publishPubliclyMinProjectsRequirement =
    useFlags()[flags.permanent.app.pios.publishPubliclyMinProjectsRequirement];

  const bookDemo = () => {
    trackEvent(EventType.EmailBookDemo, {
      product: 'Pios',
    });

    window.location.href = `mailto:sales@dimatech.se?subject=${t(
      'RegisterButton.MailSubject',
      { product: 'Pios' }
    )}`;
  };

  return (
    <View>
      <HeroProduct image={img_hero}>
        <HeroTitle>{t('Pios.Header.Title')}</HeroTitle>
        <HeroSubTitle>{t('Pios.Header.SubTitle')}</HeroSubTitle>

        <ButtonsHero>
          <Button
            text={t('BookDemoButton.Text')}
            color={ProductPrimaryColor.Pios}
            handleClick={bookDemo}
          />

          <Button
            text={t('Pios.Header.ButtonText')}
            color={ProductPrimaryColor.Pios}
            handleClick={() =>
              (window.location.href = 'https://pios.dimatech.se/register')
            }
            light
          />
        </ButtonsHero>
      </HeroProduct>

      <HeroProductIntro color={ProductPrimaryColor.Pios}>
        <Container>
          <div>
            <HeroTitle>{t('Pios.Intro.Title')}</HeroTitle>

            <HeroText style={{ maxWidth: 600, width: '100%' }}>
              {t('Pios.Intro.Text1')}
              <br />
              <br />
              {t('Pios.Intro.Text2')}
            </HeroText>

            <img
              src={img_pios_product_hero}
              alt={t('Pios.Header.Title')}
              style={{ maxHeight: 350, width: '100%' }}
            />
          </div>
        </Container>
      </HeroProductIntro>

      <Section>
        <Container>
          <div>
            <Heading2>{t('Pios.Information.HowIsItUsed.Title')}</Heading2>

            <div>
              <Trans i18nKey="Pios.Information.HowIsItUsed.Text1" />
            </div>
          </div>

          <div className="image hide-xs">
            <img
              src={img_pios_how_project_ideas}
              alt="Pios projektidéer"
              className="no-shadow"
            />
          </div>
        </Container>
      </Section>

      <Section>
        <Container wrapReverse>
          <div className="image-left hide-xs">
            <img
              src={img_pios_how_projects}
              alt="Pios projektidéer"
              className="no-shadow"
            />
          </div>

          <div>
            <div>
              <Trans i18nKey="Pios.Information.HowIsItUsed.Text2" />
            </div>
          </div>
        </Container>
      </Section>

      <Section>
        <Container>
          <div>
            <Heading2>{t('Pios.Information.Video.Title')}</Heading2>

            <div>
              <Trans i18nKey="Pios.Information.Video.Text" />
            </div>
          </div>

          <div className="image hide-xs">
            <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
              <iframe
                src="https://player.vimeo.com/video/927497849?h=b887254e34&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  border: 'none',
                }}
                title="Pios, en introduktion och metodstöd 2024"
              />
            </div>
            <script src="https://player.vimeo.com/api/player.js" />
          </div>
        </Container>
      </Section>

      <Section>
        <Container>
          <div>
            <Heading2 style={{ marginBottom: 30 }}>{t('Faq.Title')}</Heading2>

            <Question
              title={t('Faq.Pios.Questions.WhatsPiosSe.Question')}
              content={t('Faq.Pios.Questions.WhatsPiosSe.Answer')}
            />
            <Question
              title={t('Faq.Pios.Questions.DifferentLicenses.Question')}
              content={t('Faq.Pios.Questions.DifferentLicenses.Answer', {
                publishPubliclyMinProjectsRequirement,
              })}
            />
            <Question
              title={t('Faq.Pios.Questions.WhatProjectDataOnPiosSe.Question')}
              content={t('Faq.Pios.Questions.WhatProjectDataOnPiosSe.Answer')}
            />
            <Question
              title={t('Faq.Pios.Questions.PurposeOfPiosSe.Question')}
              content={t('Faq.Pios.Questions.PurposeOfPiosSe.Answer')}
            />
            <Question
              title={t(
                'Faq.Pios.Questions.ContactProjectOwnerOnPiosSe.Question'
              )}
              content={t(
                'Faq.Pios.Questions.ContactProjectOwnerOnPiosSe.Answer'
              )}
            />
            <Question
              title={t('Faq.Pios.Questions.DataStorage.Question')}
              content={t('Faq.Pios.Questions.DataStorage.Answer')}
            />
            <Question
              title={t('Faq.Pios.Questions.ExportData.Question')}
              content={t('Faq.Pios.Questions.ExportData.Answer')}
            />
            <Question
              title={t('Faq.Pios.Questions.Api.Question')}
              content={t('Faq.Pios.Questions.Api.Answer')}
            />
            <Question
              title={t('Faq.Pios.Questions.Price.Question')}
              content={t('Faq.Pios.Questions.Price.Answer')}
            />
            <Question
              title={t('Faq.Pios.Questions.HowToBuy.Question')}
              content={t('Faq.Pios.Questions.HowToBuy.Answer')}
            />
            <Question
              title={t('Faq.Pios.Questions.AccessNationalReports.Question')}
              content={t('Faq.Pios.Questions.AccessNationalReports.Answer')}
            />
            <Question
              title={t('Faq.Pios.Questions.GetHelpReadingResults.Question')}
              content={t('Faq.Pios.Questions.GetHelpReadingResults.Answer')}
            />
            <Question
              title={t('Faq.Pios.Questions.PersonalAgreement.Question')}
              content={t('Faq.Pios.Questions.PersonalAgreement.Answer')}
            />
            <Question
              title={t('Faq.Pios.Questions.InfoPolicy.Question')}
              content={t('Faq.Pios.Questions.InfoPolicy.Answer')}
            />
          </div>
        </Container>
      </Section>
    </View>
  );
};

Pios.displayName = 'Pios';
