import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { useGoogleAnalytics } from '../../hooks';
import { CookieConsentSelection } from './CookieConsentSelection';
export var CookieConsent = function () {
    var googleAnalytics = useGoogleAnalytics(false);
    var _a = useState(googleAnalytics.getHasSavedConsent()), hasSavedConsent = _a[0], setHasSavedConsent = _a[1];
    if (hasSavedConsent) {
        return null;
    }
    return (_jsx(CookieConsentSelection, { hasSavedConsent: hasSavedConsent, setHasSavedConsent: setHasSavedConsent }));
};
CookieConsent.displayName = 'CookieConsent';
