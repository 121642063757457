import { useTitle } from '@dimatech/shared/lib/hooks';
import { Home as HomeFeature } from 'features/home';

export const Home = (): JSX.Element => {
  useTitle();

  return <HomeFeature />;
};

Home.displayName = 'Home';
