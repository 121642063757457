import { useTitle } from '@dimatech/shared/lib/hooks';
import { Pios as PiosFeature } from 'features/pios';
import { useTranslation } from 'react-i18next';

export const Pios = (): JSX.Element => {
  const { t } = useTranslation();

  useTitle(t('Pios.Header.Title'));

  return <PiosFeature />;
};

Pios.displayName = 'Pios';
