import { useTitle } from '@dimatech/shared/lib/hooks';
import { InformationSecurity as InformationSecurityFeature } from 'features/policies/InformationSecurity';
import { useTranslation } from 'react-i18next';

export const InformationSecurity = (): JSX.Element => {
  const { t } = useTranslation();

  useTitle(t('Policies.InformationSecurity.Title'));

  return <InformationSecurityFeature />;
};

InformationSecurity.displayName = 'InformationSecurity';
