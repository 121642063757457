import { useTitle } from '@dimatech/shared/lib/hooks';
import { Dios as DiosFeature } from 'features/dios';
import { useTranslation } from 'react-i18next';

export const Dios = (): JSX.Element => {
  const { t } = useTranslation();

  useTitle(t('Dios.Header.Title'));

  return <DiosFeature />;
};

Dios.displayName = 'Dios';
