import { useTitle } from '@dimatech/shared/lib/hooks';
import { Dikios as DikiosFeature } from 'features/dikios';
import { useTranslation } from 'react-i18next';

export const Dikios = (): JSX.Element => {
  const { t } = useTranslation();

  useTitle(t('Dikios.Header.Title'));

  return <DikiosFeature />;
};

Dikios.displayName = 'Dikios';
