import { useTitle } from '@dimatech/shared/lib/hooks';
import { Policies as PoliciesFeature } from 'features/policies';
import { useTranslation } from 'react-i18next';

export const Policies = (): JSX.Element => {
  const { t } = useTranslation();

  useTitle(t('Policies.Title'));

  return <PoliciesFeature />;
};

Policies.displayName = 'Policies';
