import { useTitle } from '@dimatech/shared/lib/hooks';
import { UserAgreementsDikios as UserAgreementsDikiosFeature } from 'features/policies/UserAgreementsDikios';
import { useTranslation } from 'react-i18next';

export const UserAgreementsDikios = (): JSX.Element => {
  const { t } = useTranslation();

  useTitle(t('Policies.UserTermsReadMore.DownloadDikios'));

  return <UserAgreementsDikiosFeature />;
};

UserAgreementsDikios.displayName = 'UserAgreementsDikios';
