import { useTitle } from '@dimatech/shared/lib/hooks';
import { Dimios as DimiosFeature } from 'features/dimios';
import { useTranslation } from 'react-i18next';

export const Dimios = (): JSX.Element => {
  const { t } = useTranslation();

  useTitle(t('Dimios.Header.Title'));

  return <DimiosFeature />;
};

Dimios.displayName = 'Dimios';
